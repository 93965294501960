@use '../../constants';
@use '../../mixins';

footer {
  position: relative;
  @include mixins.line-height(24);
  flex: 1;
  padding: 48px 25px;
  z-index: 0;
  font-weight: 300;

  aio-footer {
    display: block;
    position: relative;
    z-index: 0;
    overflow: auto;

    & > * {
      max-width: 50em;
    }

    .footer-block {
      margin: 0 0.3rem;
    }

    a {
      text-decoration: none;
      z-index: 20;
      position: relative;

      &:hover {
        text-decoration: underline;
      }

      &:visited {
        text-decoration: none;
      }

      &:focus {
        // `outline-offset` is not applied on Chrome on Windows, if `outline-style` is `auto.
        outline: 1px solid rgba(constants.$white, 0.8);
        outline-offset: 2px;
      }
    }

    .footer-block-heading {
      @include mixins.font-size(16);
      text-transform: uppercase;
      font-weight: 400;
      margin: 8px 0 12px;

      @media (max-width: 600px) {
        @include mixins.font-size(14);
      }
    }

    p {
      text-align: center;
      margin: 10px auto 5px;

      @media (max-width: 480px) {
        text-align: left;
      }
    }

    div.grid-fluid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      $grid-gap: 24px;
      gap: $grid-gap;
      margin: 0 auto 40px;
      justify-content: center;

      ul {
        list-style-position: inside;
        padding: 0;
        margin: 0;

        li {
          list-style-type: none;
          padding: 4px 0;
          text-align: left;
        }
      }

      @media (max-width: 45rem) {
        grid-template-columns: repeat(2, calc(50% - (#{$grid-gap}/2)));

        .footer-block {
          margin: 1.5rem 2.4rem 0.8rem 20%;
        }
      }

      @media (max-width: 25rem) {
        .footer-block {
          margin-left: 0.3rem;
          margin-right: 0.3rem;
        }
      }

      @media (max-width: 20rem) {
        grid-template-columns: 1fr;
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("/assets/images/logos/angular/angular_whiteTransparent_withMargin.png")
        top 0 left 0 repeat,
      url("/assets/images/logos/angular/angular_whiteTransparent_withMargin.png")
        top 80px left 160px repeat;
    opacity: 0.05;
    background-size: 320px auto;
  }
}
